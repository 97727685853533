import * as React from "react";
import { Container, Row, } from "react-bootstrap";
import Layout from "../components/layout";
import Seo from "../components/seo";
import "../components/css/NewsDetail.scss";
import { AiFillLinkedin } from 'react-icons/ai';
import { FaTwitter } from 'react-icons/fa';
import { FaYoutube } from 'react-icons/fa';
import { FiArrowLeft } from 'react-icons/fi';
import { Link } from "gatsby";


const PressDetails = () => (
  <Layout>
    <Seo title="EdgeQ | Edgeq Launches" />


    <section className="News-detail">
        <Container>
                <Row>

                  <div className="detail-head">
                   <Link to="/media/#August" className="back-btn"><FiArrowLeft/> Company Announcements</Link>

                  <h2>EdgeQ Adds Former Federal <br />
                    Communications Commission<br />
                    Chairman Ajit Pai to its Advisory Board</h2>
                  </div>

                  <div className="detail-para">
                    
              <p className="text-left"><i>Pai Joins Former Qualcomm Executives to Advance World’s First Programmable 5G Base Station-on-a-Chip</i></p>
              
              <div className="ajit-social">
              <ul className="social-icons">
                  <li>  
                    <a aria-label="Save" href="https://www.linkedin.com/authwall?trk=bf&trkInfo=AQFQA4el-DoX9AAAAX7t1dUotlbczx1ZDTlCKPTejfiFEWL5GGZd5oqr6zWvtFKLF3CnnFGCCLco9AXaX4nEgd1nxfFvszEVY7-NA8YXEk9NExe7VTUUStGaSa7qf9cyJahXczA=&originalReferer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Fedgeq" target="_blank" rel="noopener noreferrer"><AiFillLinkedin/></a>
                  </li>
                  <li>  
                    <a aria-label="Save" href="https://twitter.com/edgeq_inc?lang=en" target="_blank" rel="noopener noreferrer"><FaTwitter/></a>
                  </li>
                  <li>  
                    <a aria-label="Save" href="https://www.youtube.com/channel/UChEG47AbYVKYw46EGhE_dcg" target="_blank" rel="noopener noreferrer"><FaYoutube/></a>
                  </li>
                </ul>
              </div>

              <p> <b>SANTA CLARA, CA – February 15, 2022 –</b> <Link to="/">EdgeQ Inc</Link>, a leader in 5G wireless infrastructure, announced today the appointment of former FCC Chairman Ajit Pai to the company’s board of advisors. Pai joins EdgeQ as the company scales to deliver industry’s first software-defined 5G and AI Base Station-on-a-Chip for wireless infrastructure.<br />

<br /> A longtime champion of open, virtualized radio access networks and wireless technology innovation, Pai implemented major initiatives during his tenure at the FCC to advance U.S. leadership in 5G. Under his leadership, Pai pursued a comprehensive strategy to push more spectrum into the marketplace, facilitate private sector investments in 5G infrastructure, and modernize outdated regulations to promote 5G backhaul and digital opportunity. He becomes the latest telecom industry luminary to join EdgeQ’s advisory board, following the appointments of former Qualcomm CEO Dr. Paul Jacobs and former Qualcomm CTO Matt Grob in 2021.<br />

<br />“5G is becoming a foundational technology for wireless communications in an increasingly connected world. It promises faster, smarter networks — which in turn require faster, smarter mobile edge infrastructure,” said Ajit Pai, former FCC Chairman. “EdgeQ’s base station-on-a-chip is just that. It converges 5G and AI in a disruptive, open, software-defined platform — One that empowers 5G entrepreneurs, developers, and users, is cost-effective, and consumes dramatically less power. This solution is poised to become a core part of the wireless networks of the future. Along with Paul Jacobs and Matt Grob, I am honored to join EdgeQ’s distinguished advisory board and look forward to helping extend its market success.”<br />

<br />EdgeQ’s open RISC-V based architecture uniquely offers a flexible, software-defined approach to traditional cellular network architecture, enabling a richer diversity of suppliers at a significantly accessible power and price point. By leveraging a highly programmable silicon with a production-ready 5G PHY software, EdgeQ provides an operating model where customers can access the advanced functionalities and critical algorithms of the 5G radio access network. It also offers an additional level of flexibility and customization to safeguard against potential future supply constraints, allowing customers to activate these features on an on-demand basis.<br />

<br />“Ajit Pai is a respected champion of greater market options, access, and deployment for communications infrastructure. We are excited to leverage his leadership in our democratization of 5G in new industries, new suppliers, and new deployment models,” said Vinay Ravuri, CEO and Founder of EdgeQ.<br />

<br />Pai joins EdgeQ as the company nears $77 million in total investments since launching from stealth in 2020. To learn more about how EdgeQ is pioneering the 5G infrastructure market, please visit <Link to="/">www.edgeq.io.</Link><br />
<br />
<b>About EdgeQ</b><br />

<br />EdgeQ is a leading innovator in 5G systems-on-a-chip. The company is headquartered in Santa Clara, CA, with offices in San Diego, CA and Bangalore, India. Led by executives from Qualcomm, Intel, and Broadcom, EdgeQ is pioneering converged connectivity and AI that is fully software-customizable and programmable. The company is backed by world renown investors. To learn more about EdgeQ, visit <Link to="/">www.edgeq.io</Link> </p><br />
</div>
                </Row>
        </Container>
    </section>


  </Layout>
);

export default PressDetails;